@import 'assets/scss/abstracts/colors.scss';

.Emphasis {
	font-weight: 600;
	color: map-get($text, '300');
	display: inline-block;

	&:hover {
		text-decoration: underline;
		cursor: default;

		a {
			cursor: pointer;
		}

		&.both,
		&.software {
			color: map-get($primary, 'main');
		}

		&.music {
			color: map-get($secondary, 'main');
		}

		&.neither {
			color: map-get($tertiary, 'main');
		}
	}
}

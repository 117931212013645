@import 'assets/scss/utilities';

.Header {
	top: 0;
	position: sticky;
	width: 50%;
	max-height: 100vh;
	height: 100%;
	padding-top: 4rem;
	padding-bottom: 6rem;

	&.mobile {
		position: relative;
		width: 100%;
		padding-top: 2rem;
		padding-bottom: 0;
	}
}

.inner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content {
}

.controls {
	@extend .w-full;
	display: flex;
	gap: 1rem;
}

.control {
	display: flex;
	gap: 0.5rem;
	cursor: pointer;
	user-select: none;

	input {
		appearance: none;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 6px;
		background-color: map-get($text, '300');
		cursor: pointer;

		&:hover {
			background-color: darken(map-get($text, '300'), 10%);
		}
	}
	input:checked {
		appearance: auto;
		clip-path: inset(0% 0% 0% 0% round 6px);
		accent-color: map-get($primary, 'main');
		&:hover {
			background-color: darken(map-get($primary, 'main'), 20%);
		}
	}
}

.header {
	margin-top: 2rem;
	h1 {
		line-height: 1.1;
	}
	@extend .w-full;
	max-width: 436px;
}

.sub-title,
.divider {
	display: inline-block;
}

.divider {
	font-weight: 600;
	color: map-get($text, '300');
	margin: 0 0.5rem;
}

.tag {
	margin-top: 1rem;
	max-width: 48ch;
}

.footer {
	display: flex;
	margin-top: 4rem;
	gap: 1.25rem;
	.link-icon {
		fill: map-get($text, 'main');
		cursor: pointer;
		transition: fill 50ms;

		&:hover {
			fill: map-get($text, '300');
		}

		&.sht:hover {
			path {
				fill: #fff;
			}
		}
	}

	&.mobile {
		margin-top: 2rem;
	}
}

.sub-titles {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	justify-content: flex-start;
	width: 100%;
	max-width: 25rem;
}

.sub-title {
	white-space: nowrap;
}

.divider {
	margin: 0 5px;
}

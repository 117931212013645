@import 'assets/scss/abstracts/colors.scss';

.Card {
	padding: 1rem;
	border-radius: 8px;

	&:hover,
	&:focus {
		background-color: rgba(map-get($background, '300'), 0.5);

		.title {
			&.software {
				h3,
				span {
					color: map-get($primary, 'main');
				}
			}
			&.music {
				h3,
				span {
					color: map-get($secondary, 'main');
				}
			}
		}

		.circle {
			background-color: map-get($primary, 'main');
		}

		.link-arrow {
			&.music {
				stroke: map-get($secondary, 'main');
			}
			&.software {
				stroke: map-get($primary, 'main');
			}
		}
	}

	.card-link:hover,
	.card-link:focus {
		.link-arrow {
			top: 0px !important;
			right: 0px !important;
		}
	}

	.project-image-ctr {
		display: flex;
		align-items: center;

		@media only screen and (max-width: 512px) {
			max-width: 50%;
		}
	}

	.image,
	.media {
		width: 90%;
		height: 80px;
		object-fit: cover;
		border-radius: 4px;
		border: 2px solid map-get($neutral, '700');

		@media only screen and (max-width: 512px) {
			width: 100%;
			height: 112px;
		}
	}

	.media {
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover,
		&:focus {
			border-color: map-get($text, 'main');

			.play-button {
				fill: map-get($text, '300');
			}
		}
	}

	.play-button {
		fill: map-get($text, 'main');
	}

	.grid {
		display: grid;
		grid-template-columns: 132px auto;

		@media only screen and (max-width: 512px) {
			display: flex;
			flex-direction: column;

			&.project,
			&.media-grid {
				flex-direction: column-reverse;
			}
		}
	}

	.info-container {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
	}

	.title {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 0 0.75rem;

		h3 {
			// todo make this a component instead
			font-weight: 600;
			font-size: 1rem;
		}
	}

	.circle {
		width: 2px;
		height: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: map-get($text, '300');
	}

	.link-arrow {
		position: relative;
		transition: top 200ms, right 200ms;
		top: 2px;
		right: 2px;
	}

	.tech-container {
		display: flex;
		flex-wrap: wrap;
		gap: 0.35rem 0.5rem;
	}

	audio {
		height: 36px;
		width: 100%;
	}

	.show {
		opacity: 1;
		display: block;
	}

	.hide {
		height: 0;
		opacity: 0;
		display: none;
	}

	.invisible {
		opacity: 0;
		visibility: hidden;
	}

	.controls {
		display: flex;
		gap: 0.5rem;
	}

	.slider {
		flex-grow: 1;
	}

	input[type='range'] {
		position: relative;
		-webkit-appearance: none;
		height: 5px;
		margin: auto;
		outline: none;
	}
	input[type='range']::-webkit-slider-runnable-track {
		width: 100%;
		height: 5px;
		cursor: pointer;
		background: map-get($text, '300');
	}
	input[type='range']::before {
		position: absolute;
		content: '';
		top: 8px;
		left: 0;
		width: var(--seek-before-width);
		height: 3px;
		background-color: yellow;
		cursor: pointer;
	}
	input[type='range']::-webkit-slider-thumb {
		position: relative;
		-webkit-appearance: none;
		box-sizing: content-box;
		height: 15px;
		width: 15px;
		border-radius: 50%;
		background-color: map-get($secondary, 'main');
		border: 1px solid map-get($background, '300');
		cursor: pointer;
		margin: -7px 0 0 0;
	}
	input[type='range']::-moz-range-track {
		width: 100%;
		height: 3px;
		cursor: pointer;
		background: blue;
	}
	input[type='range']::-moz-range-progress {
		background-color: teal;
	}
	input[type='range']::-moz-focus-outer {
		border: 0;
	}
	input[type='range']::-moz-range-thumb {
		box-sizing: content-box;
		height: 15px;
		width: 15px;
		border-radius: 50%;
		background-color: rosybrown;
		cursor: pointer;
	}
	input[type='range']::-ms-track {
		width: 100%;
		height: 3px;
		cursor: pointer;
		background: transparent;
		border: solid transparent;
		color: transparent;
	}
	input[type='range']::-ms-fill-lower {
		background-color: #007db5;
	}
	input[type='range']::-ms-fill-upper {
		background: linear-gradient(
			to right,
			rgba(0, 125, 181, 0.6) var(--buffered-width),
			rgba(0, 125, 181, 0.2) var(--buffered-width)
		);
	}
	input[type='range']::-ms-thumb {
		box-sizing: content-box;
		height: 15px;
		width: 15px;
		border-radius: 50%;
		background-color: brown;
		cursor: pointer;
	}
}

@import 'assets/scss/abstracts/colors.scss';

.About {
	.dogs {
		position: absolute;
	}

	.gym {
		&:hover {
			cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>💪</text></svg>")
					16 0,
				auto;
		}
	}

	.wow {
		&:hover {
			cursor: url('../../assets/images/wow.png') 16 0, auto;
		}
	}

	.elden-ring {
		&:hover {
			cursor: url('../../assets/icons/elden-ring-seeklogo.svg') 32 24, auto;
		}
	}
}

@import 'assets/scss/abstracts/colors.scss';
@import 'assets/scss/utilities';

.Contact {
	width: 100%;

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		font-family: inherit;
		margin-bottom: 6rem;

		input,
		textarea {
			font-family: inherit;
			font-size: inherit;
			background-color: map-get($text, 'main');
			border-radius: 4px;
			height: 2.25rem; // 36px
			padding: 0px 8px;
			color: map-get($background, main);

			&:focus {
				outline: none;
				border: none;
				background-color: map-get($text, '300');
			}

			&:focus + label {
				color: map-get($text, '300');
			}

			&.required-input {
				&:valid {
					background-color: map-get($text, '300');
				}
				&:valid + label {
					color: map-get($text, '300');
				}
			}
			&.error {
				background-color: map-get($error, '100') !important;
			}
		}

		textarea {
			height: auto;
			resize: none;
			padding-top: 8px;
		}

		button {
			height: 2.25rem;
			width: 100%;
			text-align: center;
			color: map-get($text, '300');
			border-radius: 4px;
			font-weight: 600;
			cursor: pointer;

			&.software {
				background-color: map-get($primary, '500');

				&:hover,
				&:focus {
					background-color: map-get($primary, '600');
				}
			}
			&.music {
				background-color: map-get($secondary, 'main');

				&:hover,
				&:focus {
					background-color: (map-get($secondary, '400'));
				}
			}
			&.both {
				background: linear-gradient(
					345deg,
					map-get($primary, '500'),
					map-get($secondary, 'main')
				);
				animation: 20s infinite linear wandering-gradient;
				animation-timing-function: ease-in-out;
				-webkit-animation-timing-function: ease-in-out;

				&:hover,
				&:focus {
					background: linear-gradient(
						345deg,
						map-get($primary, '600'),
						map-get($secondary, '400')
					);
				}
			}
			&.neither {
				background-color: map-get($tertiary, 'main');
			}
			&:disabled {
				background: map-get($neutral, '450') !important;
				color: map-get($neutral, '600');
				cursor: not-allowed;
			}
		}

		.input-container {
			display: flex;
			flex-direction: column-reverse;
			margin-bottom: 0.75rem;
		}

		span.error {
			color: map-get($error, 'main') !important;
			font-size: 0.85rem;
		}
	}
}

@keyframes wandering-gradient {
	0% {
		background: linear-gradient(
			345deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	10% {
		background: linear-gradient(
			315deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	20% {
		background: linear-gradient(
			285deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	30% {
		background: linear-gradient(
			255deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	40% {
		background: linear-gradient(
			225deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	50% {
		background: linear-gradient(
			195deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	60% {
		background: linear-gradient(
			225deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	70% {
		background: linear-gradient(
			255deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	80% {
		background: linear-gradient(
			285deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	90% {
		background: linear-gradient(
			315deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
	100% {
		background: linear-gradient(
			345deg,
			map-get($primary, '600'),
			map-get($secondary, '400')
		);
	}
}

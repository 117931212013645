@import 'assets/scss/abstracts/colors.scss';

.w-full {
	width: 100%;
}

.m {
	&b {
		&-8 {
			margin-bottom: 2rem;
		}
	}
}

.emphasis {
	color: map-get($text, '300');
	font-weight: 600;
}

.pointer {
	cursor: pointer;
}

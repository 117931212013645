@import 'assets/scss/abstracts/colors.scss';

.Navigation {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 4rem;
}
.nav-item {
	display: flex;
	align-items: center;
	gap: 1rem;

	&.active {
		.nav-indicator {
			background-color: map-get($text, '300');
			width: 76px;
		}
		a {
			color: map-get($text, '300');
			font-weight: 600;
		}
	}
}

.nav-indicator {
	background-color: map-get($text, 'main');
	display: inline-block;
	width: 42px;
	height: 1px;
	transition: 100ms width ease-out;
}

a {
	color: inherit;
	text-decoration: none;
	font-size: 1.125rem;
}

.mobile-nav-item {
	position: sticky;
	top: 0;
}

.glass-effect {
	background: rgb(15 23 42 / 10%);
	border-radius: 4px;
	padding: 1rem 3rem;
	backdrop-filter: blur(14px);
	-webkit-backdrop-filter: blur(10px);
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	color: white;
	text-align: center;
	margin-left: -3rem;
	margin-right: -3rem;

	@media only screen and (max-width: 512px) {
		padding: 1rem;
		margin-left: -1rem;
		margin-right: -1rem;
	}
}

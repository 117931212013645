@import 'assets/scss/abstracts/colors.scss';
@import 'assets/scss/utilities';
@import 'components/Header/Header.scss';

* {
	margin: 0;
	padding: 0;

	&.software * {
		&::selection {
			background-color: map-get($primary, 'main');
			color: map-get($background, 'main');
		}
	}
	&.music * {
		&::selection {
			background-color: map-get($secondary, 'main');
			color: map-get($background, 'main');
		}
	}
	&.neither * {
		&::selection {
			background-color: map-get($tertiary, 'main');
			color: map-get($background, 'main');
		}
	}
	&.both * {
		&::selection {
			background-color: map-get($primary, 'main');
			color: map-get($background, 'main');
		}
	}
}

h1 {
	font-size: 4rem; // 48px
	font-weight: 700;
	@extend .emphasis;
}

h3 {
	font-size: 1.25rem; // 20px
	@extend .emphasis;
}

a {
	text-decoration: none;
	color: inherit;
}

button,
input {
	all: unset;
}

.main {
	position: relative;
	background-color: map-get($background, 'main');
	color: map-get($text, 'main');
	min-height: 100vh;
	max-width: 100vw;
	padding: 0 3rem;
	display: flex;
	justify-content: center;

	height: max-content;

	@media only screen and (max-width: 512px) {
		padding: 0 1rem;
	}
}

.main-inner {
	height: 100%;
	width: 100%;

	max-width: 1280px;

	display: flex;
	gap: 1rem;

	&.mobile {
		flex-direction: column;
	}
}
